import marked from 'marked'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeSvgSingleLine from 'src/components/makeSvgSingleLine'
import { Link, parseList } from 'src/components/useLinkList'
import getLinkVariables from 'src/helpers/linkVariables';

type LinkGroup = {
  title: string;
  links: Link[];
};

export const parseGroupLinks = (markdown: string): LinkGroup[] => {
  const tokens = marked.lexer(makeSvgSingleLine(markdown));
  const groups: LinkGroup[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading':
        groups.push({ title: token.text, links: [] });
        break;
      case 'list':
        groups[groups.length - 1].links = parseList(token.items);
        break;
      default:
        break;
    }
  }
  return groups;
};

const useGroupedLinks = (resources) => {
  const { t, ready } = useTranslation();
  const [items, setItems] = useState<LinkGroup[]>([]);

  useEffect(() => {
    if (resources) {
      const markdown = t(resources, getLinkVariables());
      setItems(parseGroupLinks(markdown));
    }
  }, [ready, resources]);

  return items;
};

export default useGroupedLinks;
