import React from 'react';
import { GlobalFooter as OldGlobalFooter } from '@hagerty/react-components';
import { GlobalFooter } from '@hagerty/global-navigation';
import { useTreatments } from '@splitsoftware/splitio-react';

import useGroupedLinks from 'src/components/useGroupedLinks';
import { Split, Treatment } from 'src/constants/Split';

import { normalizeNavigationLinks, normalizeLinkGroups, normalizeSocialLinks } from './util';
import type { ContentfulLinks, ContentfulLinkGroups } from './types';

const MainFooter = () => {
  const simpleGroups = useGroupedLinks('site-footer.links');
  const footerGroupedLinks = useGroupedLinks('footer.grouped-links');
  const secondaryGroups = useGroupedLinks('contact.footer');

  const treatments = useTreatments([Split.FeatureAskHagerty, Split.FeatureGlobalNav]);
  const isFeatureGlobalNav = treatments[Split.FeatureGlobalNav].treatment === Treatment.On;

  if (isFeatureGlobalNav) {
    return (
      <GlobalFooter
        dataApi="https://api.hagerty.com/gns/v1/footer/usdirect"
        newsletter={{ isProd: process.env.SENTRY_ENVIRONMENT === 'prod' }}
      />
    );
  }

  if (!footerGroupedLinks[0] && !secondaryGroups[0] && !simpleGroups[0]) return null;

  return (
    <OldGlobalFooter
      sitemap={{
        linkGroups: normalizeLinkGroups(footerGroupedLinks as ContentfulLinkGroups),
      }}
      secondary={{
        socialLinks: normalizeSocialLinks(secondaryGroups[0].links as ContentfulLinks),
        navigationLinks: normalizeNavigationLinks(secondaryGroups[1].links as ContentfulLinks),
      }}
      simple={{ navigationLinks: normalizeNavigationLinks(simpleGroups[1].links as ContentfulLinks) }}
    />
  );
};

export { MainFooter };
