import type { GlobalFooterTypes, IconTypes } from '@hagerty/react-components';

import type { ContentfulLinks, ContentfulLinkGroups } from './types';

const socialIconName = (href: string): IconTypes.IconType => {
  if (href.includes('facebook')) return 'Facebook16';

  if (href.includes('instagram')) return 'Instagram16';

  if (href.includes('linkedin')) return 'Linkedin16';

  if (href.includes('youtube')) return 'Youtube16';

  if (href.includes('twitter')) return 'Twitter16';
};

const normalizeNavigationLinks = (contentfulLinks: ContentfulLinks): GlobalFooterTypes.LinkProps[] => {
  return contentfulLinks.map(
    ({ href, label }): GlobalFooterTypes.LinkProps => ({
      href,
      displayText: label,
    })
  );
};

const normalizeLinkGroups = (contentfulLinkGroups: ContentfulLinkGroups): GlobalFooterTypes.LinkGroupProps[] => {
  return contentfulLinkGroups.map(
    ({ title, links }): GlobalFooterTypes.LinkGroupProps => ({
      groupLabel: title,
      links: normalizeNavigationLinks(links),
    })
  );
};

const normalizeSocialLinks = (contentfulLinks: ContentfulLinks): GlobalFooterTypes.SecondaryProps['socialLinks'] => {
  return contentfulLinks.map(({ href }): GlobalFooterTypes.SecondaryProps['socialLinks'][number] => {
    const linkLabel = href.match(/^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)(?:\.com)/i)[1];

    return {
      icon: {
        icon: socialIconName(href),
        color: 'dark',
      },
      buttonType: 'Secondary',
      buttonLabel: linkLabel,
      href,
    };
  });
};

export { normalizeNavigationLinks, normalizeLinkGroups, normalizeSocialLinks };
