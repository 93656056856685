import { useEffect, useState } from 'react';
import { useTreatments } from '@splitsoftware/splitio-react';

import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { Split, Treatment } from '../constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import isBrowser from 'src/helpers/isBrowser';

// Join the club link for current user:
//  - isPurchaseOptimized - /checkout/information
//  - Unauthenticated - /checkout/
//  - Non-member - /checkout/
export const joinTheClubLink = (isPurchaseOptimized): string => {
  const checkoutPath = isPurchaseOptimized
    ? `/checkout/information/${location.search}`
    : `/checkout/${location.search}`;
  return checkoutPath;
};

/**
 * Check the user status and return the link for user when he/she clicks the `Join The Club` button.
 */
export default function useJoinTheClubLink(isMocked = false): string {
  const [link, setLink] = useState('/checkout/');
  let isPurchaseOptimized = false;
  let splitIsReady = false;
  let isFreeTrial = false;

  if (!isMocked) {
    // Use isEligibleForFreeTrial to check if customer has previously had free trial as well
    const { isEligibleForFreeTrial } = useFreeTrial();
    isFreeTrial = isEligibleForFreeTrial;
    const treatments = useTreatments([Split.FeaturePurchaseFlowOptimization, Split.FeatureCreateAccountCheckoutFlow]);
    splitIsReady = isReady();
    const hasPurchaseOptimizationFeature = treatments[Split.FeaturePurchaseFlowOptimization].treatment === Treatment.On;
    const hasCreateAccountCheckoutFlow = treatments[Split.FeatureCreateAccountCheckoutFlow].treatment === Treatment.On;
    isPurchaseOptimized = hasCreateAccountCheckoutFlow || hasPurchaseOptimizationFeature || isEligibleForFreeTrial;
  }

  useEffect(() => {
    setLink(joinTheClubLink(isPurchaseOptimized));
  }, [splitIsReady, isFreeTrial, isBrowser && location.search]);

  return link;
}
