import React from 'react';
import { GlobalFooter } from '@hagerty/react-components';

import useGroupedLinks from 'src/components/useGroupedLinks';

import { normalizeNavigationLinks } from './util';
import type { ContentfulLinks } from './types';

const SimpleFooter = () => {
  const simpleGroups = useGroupedLinks('site-footer.links');

  if (!simpleGroups[1]) return null;

  return (
    <GlobalFooter simple={{ navigationLinks: normalizeNavigationLinks(simpleGroups[1].links as ContentfulLinks) }} />
  );
};

export { SimpleFooter };
