import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

const FadeIn = ({ children, up, duration, style }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: duration, ease: 'easeIn' }}
      variants={{
        visible: { y: 0, opacity: 1 },
        hidden: { y: up ? 10 : 0, opacity: 0 },
      }}
      style={style}
    >
      {children}
    </motion.div>
  );
};

FadeIn.propTypes = {
  children: PropTypes.any,
  duration: PropTypes.number,
  up: PropTypes.bool,
  style: PropTypes.object,
};

export default FadeIn;
