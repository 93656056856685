import useProfile from 'src/api/useProfile';
import { isAgent } from 'src/api/client';
import isBrowser from 'src/helpers/isBrowser';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';

export const useFreeTrial = () => {
  const { data: profile, loading: profileLoading } = useProfile();
  const splitIsReady = isReady();
  const freeTrialIsReady = splitIsReady && !profileLoading;
  const treatments = useTreatments([Split.FeatureFreeTrial]);
  const hasFreeTrial = treatments[Split.FeatureFreeTrial].treatment === Treatment.On && !isAgent;
  const hadFreeTrialProduct =
    (isBrowser && localStorage.getItem('hadFreeTrialProduct')) || profile?.hadFreeTrialProduct;
  const isEligibleForFreeTrial = hasFreeTrial && !hadFreeTrialProduct && freeTrialIsReady;

  return {
    freeTrialIsReady,
    splitIsReady,
    hasFreeTrial,
    hadFreeTrialProduct,
    isEligibleForFreeTrial,
  };
};
