import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';
import getLinkVariables from 'src/helpers/linkVariables';
import svgDataURL from 'src/helpers/svgDataURL';

type TextLink = {
  id?: string;
  label: string;
  href?: string;
};

type IconLink = {
  id?: string;
  svg: string;
  href?: string;
};

export type Link = TextLink | IconLink;

const svgPrefix = '<svg';

export const parseList = (items: marked.Tokens.ListItem[]): Link[] => {
  const links: Link[] = [];
  for (const item of items) {
    if (item.tokens[0].type !== 'text') continue;
    const text = item.tokens[0] as marked.Tokens.Text;

    if (text.tokens?.[0]?.type !== 'link') {
      links.push({
        label: text.text,
      });
      continue;
    }
    const link: marked.Tokens.Link = text.tokens[0];
    if (link.text.startsWith(svgPrefix)) {
      links.push({
        href: link.href,
        svg: svgDataURL(link.text),
      });
    } else {
      links.push({
        href: link.href,
        label: link.text,
      });
    }
  }

  return links;
};

export const parseLinkList = (markdown: string): Link[] => {
  const list = marked.lexer(markdown).find((t) => t.type === 'list') as marked.Tokens.List;
  return parseList(list.items);
};

const useLinkList = (resources): Link[] => {
  const { t, ready } = useTranslation();
  return useMemo(() => {
    if (resources && ready) {
      const markdown = t(resources, getLinkVariables());
      return parseLinkList(markdown);
    }
    return [];
  }, [ready, resources]);
};

export default useLinkList;
